$menu-text-transform: none !default;
$menu-color: none !default;
$menu-meta-font-size: 90% !default;
$menu-negative-margin: false !default;

.menu {
    li {
        a {
            outline:none;
        }
    }
}
@if $menu-text-transform != none {
    .menu {
        text-transform: $menu-text-transform;
    }
}

@if $menu-color != none {
    .menu a{
        color: $menu-color;
    }
}
@if $menu-negative-margin {
    .page-header .menu {
        margin-left: get-side($menu-item-padding, left) * -1;
        margin-right: get-side($menu-item-padding, right) * -1;
    }
}

.nav-meta .menu {
    a {
        font-size: $menu-meta-font-size;
    }
}
