// Source: https://designpieces.com/2012/12/social-media-colours-hex-and-rgb/

/// Button size
/// @group Social
/// @type Size
$social-brand-size: 35px !default;

/// Icon size
/// @group Social
/// @type Size
$social-brand-icon-size: 25px !default;

/// Defines the color of the social icon facebook.
/// @group Social
/// @type Color
$social-brand-facebook: #3b5998 !default;

/// Defines the color of the social icon twitter.
/// @group Social
/// @type Color
$social-brand-twitter: #55acee !default;

/// Defines the color of the social icon youtube.
/// @group Social
/// @type Color
$social-brand-youtube: #bb0000 !default;

/// Defines the color of the social icon instagram.
/// @group Social
/// @type Color
$social-brand-instagram: #125688 !default;

/// Defines the color of the social icon google plus.
/// @group Social
/// @type Color
$social-brand-googleplus: #dd4b39 !default;

// $social-brand-linkedin: #007bb5 !default;
// $social-brand-pinterest: #cb2027 !default;
// $social-brand-github: #000000;
// $social-brand-tumblr: #32506d;

/// Margin between icons
/// @group Social
/// @type Size
$social-brand-gap: none !default;

/// Icon color
/// @group Social
/// @type Color
$social-icon-color: $white !default;


.social {
  display: inline-block;
  text-align: center;
  font-size: $social-brand-icon-size;
  @if $social-brand-size != auto {
    width: $social-brand-size;
    height: $social-brand-size;
    line-height: $social-brand-size;
  }
  + .social {
    @if $social-brand-gap != none {
      margin-left: $social-brand-gap;
    }

  }

  &.facebook {
    @include button-style($social-brand-facebook, auto, $social-icon-color);
  }
  &.twitter {
    @include button-style($social-brand-twitter, auto, $social-icon-color);
  }
  // &.linkedin {
  //   @include button-style($social-brand-linkedin, auto, $social-icon-color);
  // }
  &.youtube {
    @include button-style($social-brand-youtube, auto, $social-icon-color);
  }
  &.instagram {
    @include button-style($social-brand-instagram, auto, $social-icon-color);
  }
  // &.pinterest {
  //   @include button-style($social-brand-pinterest, auto, $social-icon-color);
  // }
  &.googleplus {
    @include button-style($social-brand-googleplus, auto, $social-icon-color);
  }
  // &.github {
  //   @include button-style($social-brand-github, auto, $social-icon-color);
  // }
  // &.tumblr {
  //   @include button-style($social-brand-tumblr, auto, $social-icon-color);
  // }


  > .fa {
    color: $social-icon-color;
    font-size: $social-brand-icon-size;
  }
}
