.ce-type-imageteaser,
.ce-type-imageteasersquare,
.ce-type-imageteasertall,
.ce-type-imageteaserwide {
    .ce-inner {
        position: relative;
        background: $card-background;
        box-shadow: $card-shadow;
        color: $card-font-color;
        border: $card-border;
        border-radius: $card-border-radius;
        display: flex;
        flex-direction: column-reverse;
    }
    header {
        background: $card-divider-background;
        padding: $global-padding;
        background: $white;
        position: absolute;
        bottom: 0;
        margin: 0 $global-margin 0-$global-margin;
        @include breakpoint(medium) {
            margin: $global-margin;
        }
        h2 + h3 {
            margin: 0;
        }
    }
}
