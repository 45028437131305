////
/// Advanced typography styles
/// Defines style in the header map in the settings file
////

/// Font size for figcaption.
/// @type size
$figcaption-font-size: $small-font-size !default;

/// Enable a transition for links.
/// @type transition
$anchor-transition: none !default;

figcaption {
    font-size: $figcaption-font-size;
}


a {
    @if $anchor-transition != none {
        transition: $anchor-transition;
    }
}


@each $size, $headers in $header-styles {
    @include breakpoint($size) {
        @each $header, $header-defs in $headers {
            #{$header} {

                @if map-has-key($header-defs, 'font-family') {
                    font-family: (map-get($header-defs, 'font-family'));
                }
            }
        }
    }
}

@each $size, $headers in $header-styles {
    @include breakpoint($size) {
        @each $header, $header-defs in $headers {
            #{$header} {

                @if map-has-key($header-defs, 'color') {
                    color: (map-get($header-defs, 'color'));
                }
            }
        }
    }
}

@each $size, $headers in $header-styles {
    @include breakpoint($size) {
        @each $header, $header-defs in $headers {
            #{$header} {

                @if map-has-key($header-defs, 'font-weight') {
                    font-weight: (map-get($header-defs, 'font-weight'));
                }
            }
        }
    }
}

@each $size, $headers in $header-styles {
    @include breakpoint($size) {
        @each $header, $header-defs in $headers {
            #{$header} {

                @if map-has-key($header-defs, 'margin-bottom') {
                    margin-bottom: (map-get($header-defs, 'margin-bottom'));
                }
            }
        }
    }
}