// $grid-gallery-gutter: $grid-column-gutter !default;

/// Defines the horizontal gutter space between each item of a gallery.
/// @group Content
/// @todo Define naming and function of variable with r3h6.
/// @type Number
$grid-gallery-gutter: (
  small: 2px
);

.ce-type-gallery {
    img[data-original-src] {
        cursor: pointer;
    }
}

.grid-container {
    display: flex;
    flex-wrap: wrap;
    @each $breakpoint, $gutter in $grid-gallery-gutter {
       @include breakpoint($breakpoint){
           margin-left: rem-calc($gutter/-2);
           margin-right: rem-calc($gutter/-2);
       }
   }
    // margin: .125rem auto;
    // max-width: 100%;
    // margin: 0 auto;
    // @media screen and (min-width: 100rem) {
    //     max-width: 100rem;
    // }
    &::after {
        content: '';
        flex-grow: 999999999;
        min-width: 20%;
        order: 999999999;
    }
    // .caption {
    //     transition: opacity 300ms ease-out;
    //     opacity: 0;
    //     font-size: .825em;
    //     font-style: italic;
    //     color: #fff;
    //     background-color: rgba(0, 0, 0, .35);
    //     padding: .25rem;
    //     position: absolute;
    //     z-index: 1;
    //     bottom: 0;
    //     left: 0;
    //     right: 0;
    // }
    .grid-item {
        @each $breakpoint, $gutter in $grid-gallery-gutter {
           @include breakpoint($breakpoint){
               margin: rem-calc($gutter/2);
           }
        }
        // background-color: #f0f0f0;
        position: relative;
        // &:hover .caption {
        //     opacity: 1;
        // }
        // @media screen and (min-width: 64rem) {
        //     margin: .125rem;
        // }
    }
    .uncollapse {
        // Stop layout collapsing before the images have loaded
        display: block;
        background-color: #f0f0f0;
    }
    .image {
        position: absolute;
        vertical-align: bottom;
        top: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}