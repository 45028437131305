/// Defines the font size inside of navmain.
/// @group Header
/// @type Number
$navmain-font-size: none !default;

/// Defines the font weight inside of navmain.
/// @group Header
/// @type String | Number
$navmain-font-weight: normal !default;

/// Defines the behavior of two borders, so that they overlap and not add up to a double border in navmain.
/// @group Header
/// @type Bool
$navmain-border-overlapping: true !default;

/// Defines the border bottom of navmain, in some cases this border separates the navigation from the main content below.
/// @group Header
/// @type String
$navmain-border-bottom-style: none !default;

/// Defines the behavior for navmain if the navigation should align vertically.
/// @group Header
/// @type String
$navmain-vertical-align: none !default;

/// Defines the behavior for navmain if the navigation should align horizontally.
/// @group Header
/// @type String
$navmain-horizontal-align: none !default;

/// Defines the position of navmain.
/// @group Header
/// @type Value
$navmain-position-fix: none !default;


$navmain-font-familiy: none !default;

/// Defines the color of level 1 navigation in navmain.
/// @group Header
/// @type Color
$navmain-level1-color: none !default;

/// Defines the font weight of level 1 navigation in navmain.
/// @group Header
/// @type String | Number
$navmain-level1-font-weight: none !default;

/// Defines the font weight of an active item on level 2 navigation in navmain.
/// @access public
/// @group header
$navmain-level2-active-font-weight: none !default;

/// Defines the color of an active menu item in navmain.
/// See: $menu-item-color-active
/// @group Header
/// @type Color
$navmain-item-color-active: $menu-item-color-active !default;

/// Defines the color of the hover state of an menu item in navmain.
/// See: $menu-item-color-hover
/// @group Header
/// @type Color
$navmain-item-color-hover: none !default;

/// Defines the the border style of the active navigation element in navmain.
/// @group Header
/// @type String
$navmain-item-active-border-bottom-style: auto !default;
@if $navmain-item-active-border-bottom-style == auto and length($navmain-border-bottom-style) == 3 {
    $navmain-item-active-border-bottom-style: nth($navmain-border-bottom-style, 1) nth($navmain-border-bottom-style, 2) $anchor-color;
}

/// Defines the the border style of the navigation element in navmain that is currently selected.
/// See: $navmain-item-active-border-bottom-style
/// @group Header
/// @type String
$navmain-item-current-border-bottom-style: $navmain-item-active-border-bottom-style !default;

/// Defines the the underline style of the navigation element in navmain that is currently active.
/// @group Header
/// @type Bool
$navmain-item-active-underline: false !default;

/// Defines the the underline style of the navigation element in navmain that is currently selected.
/// See: $navmain-item-active-underline
/// @group Header
/// @type String
$navmain-item-current-underline: $navmain-item-active-underline !default;

/// Creates a line bellow the item using a pseude element.
/// @type boolean
$navmain-level1-active-underline2: false !default;

/// Defines the border-style for navmain-level1-active-underline2
/// Default: 2px solid $primary-color;
/// @type string
$navmain-level1-active-underline2-style: 2px solid $primary-color !default;

/// Level 2 active background color.
/// @type color
$navmain-level2-background-active: none !default;

/// Define main nav styles
/// @atype array
$navmain-level-styles: (
    // 1: (
    //     default: (font-family: Arial, color: #AAA, font-size: 1rem, font-weight: bold),
    //     hover: (color: #BBB),
    //     active: (color: #BBB),
    //     current: (color: #BBB),
    // )
) !default;

.nav-main {
    @if $navmain-position-fix != none {
        @warn "Deprecated! $navmain-position-fix";
        position: $navmain-position-fix;
    }


    .is-menuitem-active > a, .is-menuitem-current > a {
        color: $navmain-item-color-active;
    }
    a:hover {
        color: $navmain-item-color-hover;
    }

    @if $navmain-font-familiy != none {
        font-family: $navmain-font-familiy;
    }


    @if $navmain-vertical-align == top {
        align-self: flex-start;
    }
    @if $navmain-vertical-align == middle {
        align-self: flex-center;
    }
    @if $navmain-vertical-align == bottom {
        align-self: flex-end;
    }
    @if $navmain-horizontal-align == left {
        > .menu {
            justify-content: flex-start;
        }
    }
    @if $navmain-horizontal-align == center {
        > .menu {
            justify-content: center;
        }
    }
    @if $navmain-horizontal-align == right {
         > .menu {
            justify-content: flex-end;
        }
    }
    @if $navmain-border-bottom-style != none {
        border-bottom: $navmain-border-bottom-style;

        @if $navmain-border-overlapping {
            // margin-top: -nth($navmain-border-bottom-style, 1);
            > .menu {
                // position: relative;
                margin-bottom: nth($navmain-border-bottom-style, 1) * -1;
                > li {
                    > a {
                        border-bottom: nth($navmain-item-active-border-bottom-style, 1) nth($navmain-item-active-border-bottom-style, 2) transparent;
                    }
                    &.is-menuitem-active > a {
                        border-bottom: $navmain-item-active-border-bottom-style;
                    }
                    &.is-menuitem-current > a  {
                        border-bottom: $navmain-item-current-border-bottom-style;
                    }
                }
            }
        }
    }

    @if $navmain-item-active-underline {
        .is-menuitem-active > a {
            text-decoration: underline;
        }
        .is-menuitem-current > a  {
            text-decoration: underline;
        }
    }
    @if $navmain-level1-active-underline2 {
        .level1 > .is-menuitem-active > a,
        .level1 > .is-menuitem-current > a{
            position: relative;
            &::before {
                content: "";
                position: absolute;
                left: 0;
                right: 0;
                bottom: 0;
                border-bottom: $navmain-level1-active-underline2-style;
                margin: $menu-items-padding;
                margin-top: 0;
                margin-bottom: 0;
            }
        }
        .level1 > .is-menuitem-active.is-dropdown-submenu-parent > a,
        .level1 > .is-menuitem-current.is-dropdown-submenu-parent > a {
            &::before {
                margin-right: 0;
            }
        }
    }

    @if $navmain-level2-background-active != none {
        .level2 > li.is-menuitem-active > a {
            @warn 'Depracted variable $navmain-level2-background-active, use $navmain-level-styles instead!';
            background-color: $navmain-level2-background-active;
        }
    }


    @if $navmain-font-size != none {
        font-size: $navmain-font-size;
    }

    @if $navmain-font-weight != normal {
        font-weight: $navmain-font-weight;
    }


    @if $navmain-level1-color != none {
        >.level1 > li > a {
            @warn 'Depracted variable $navmain-level1-color, use $navmain-level-styles instead!';
            color: $navmain-level1-color;
        }
    }
    @if $navmain-level1-font-weight != none {
        >.level1 > li > a {
            @warn 'Depracted variable $navmain-level1-font-weight, use $navmain-level-styles instead!';
            font-weight: $navmain-level1-font-weight;
        }
    }
    @if $navmain-level2-active-font-weight != none {
        >.level2 > .is-menuitem-active > a {
            @warn 'Depracted variable $navmain-level2-active-font-weight, use $navmain-level-styles instead!';
            font-weight: $navmain-level2-active-font-weight;
        }
    }

    @each $level, $states-defs in $navmain-level-styles {
        .level#{$level} > li > a {
            @if map-has-key($states-defs, default) {
                @each $style, $value in map-get($states-defs, default) {
                    #{$style}: $value;
                }
            }
            @if map-has-key($states-defs, hover) {
                &:hover {
                    @each $style, $value in map-get($states-defs, hover) {
                        #{$style}: $value;
                    }
                }
            }

            // @if map-has-key($styles-defs, font-size) {
            //     font-size: rem-calc(map-get($styles-defs, font-size));
            // }
            // @if map-has-key($styles-defs, font-weight) {
            //     font-weight: map-get($styles-defs, font-weight);
            // }
            // @if map-has-key($styles-defs, color) {
            //     color: map-get($styles-defs, color);
            // }
            // &:hover {
            //     @if map-has-key($styles-defs, color-hover) {
            //         color: map-get($styles-defs, color-hover);
            //     } @elseif map-has-key($styles-defs, color) {
            //         color: scale-color(map-get($styles-defs, color), $lightness: -14%);
            //     } @else {
            //         color: $anchor-color-hover;
            //     }
            // }
        }
        @if map-has-key($states-defs, active) {
            .level#{$level} > .is-menuitem-active > a {
                @each $style, $value in map-get($states-defs, active) {
                    #{$style}: $value;
                }
            }
        }
        // .level#{$level} > .is-menuitem-active > a {
        //     @if map-has-key($styles-defs, color-active) {
        //         color: map-get($styles-defs, color-active);
        //     }
        // }
    }
}
