.ui-datepicker {
    .ui-state-highlight,
    .ui-widget-content .ui-state-highlight,
    .ui-widget-header .ui-state-highlight {
        background: $primary-color;
        border-color: $primary-color;
        color: $white;
        &:hover {
            background: darken($primary-color, 2%);
        }
    }
}