////
/// Applies foundation button styles to bootstrap like button classes (btn).
/// @group Button
////

.btn {
    @include button;
}

@each $name, $color in $button-palette {
  @if $button-fill != hollow {
    .btn-#{$name} {
      @include button-style($color, auto);
    }
  }
  @else {
    .btn-#{$name} {
      @include button-hollow-style($color);
    }

    .btn-#{$name}.dropdown::after {
      border-top-color: $color;
    }
  }
}