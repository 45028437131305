/// Defines the padding space of the top bar.
/// See: $topbar-padding
/// @group Header
/// @type Number
$page-topbar-padding: $topbar-padding !default;

/// Defines the padding space of the top bar.
/// See: $topbar-padding
/// @group Header
/// @type Number
$page-topbar-margin: 0 !default;

/// Defines the background of the top bar.
/// See: $topbar-background
/// @group Header
/// @todo Please clarify what this variable does. If this variable changes the background color, name the variable $page-topbar-background-color and create a new variable if there is the possibility to set a background image $page-topbar-background-image.
$page-topbar-background: $topbar-background !default;

/// Defines the shadow of the top bar.
/// @group Header
/// @type String
$page-topbar-shadow: none !default;


/// Defines the border top of the top bar.
/// @group Header
/// @type String
$page-topbar-border-top: none !default;

/// Defines the border bottom of the top bar.
/// @group Header
/// @type String
$page-topbar-border-bottom: none !default;

/// Defines the border top of the title bar.
/// @group Header
/// @type String
$page-titlebar-border-top: none !default;

/// Defines the border bottom of the title bar.
/// @group Header
/// @type String
$page-titlebar-border-bottom: none !default;

/// Defines the height of the top bar.
/// @group Header
/// @type String
$page-topbar-height: 0 !default;

/// Defines the height of the top bar after scroll.
/// @group Header
/// @type String
$page-topbar-height-shrink: none !default;

/// Defines the height of the logo in the top bar after scroll.
/// @group Header
/// @type String
$page-topbar-logo-shrink: 100% !default;

/// Defines the transition speed of the top bar after scroll.
/// @group Header
/// @type String
$page-topbar-transition: all 0.1s ease-in-out !default;



// $page-topbar-submenu-background: $topbar-submenu-background !default;
// $page-topbar-title-spacing: $topbar-title-spacing !default;
// $page-topbar-input-width: $topbar-input-width !default;
// $page-topbar-unstack-breakpoint: $topbar-unstack-breakpoint !default;
$page-header-breakpoint: large !default;

$title-bar-logo-position: 0 auto !default;



.page-header{
            margin: $page-topbar-margin;

    .title-bar{
        @include breakpoint($page-header-breakpoint) {
            display:none;
        }
        a {
            margin: 0 auto;
            img {
                .logo {
                    margin: $title-bar-logo-position;
                }
            }
        }
        border-top: $page-titlebar-border-top;
        border-bottom: $page-titlebar-border-bottom;

    }

    .page-topbar{
        display: none;
        @include breakpoint($page-header-breakpoint) {
            display:block;
        }
        padding: $page-topbar-padding;
        background: $page-topbar-background;
        border-top: $page-topbar-border-top;
        border-bottom: $page-topbar-border-bottom;
        @if $page-topbar-height > 0 {
            height: $page-topbar-height;
        }


        @if $page-topbar-shadow != none {
            box-shadow: $page-topbar-shadow;
        }
    }

    // .is-stuck {
    //     .page-topbar {
    //         height: $page-topbar-height-shrink;
    //         transition: $page-topbar-transition;
    //         .logo {
    //             width: $page-topbar-logo-shrink;
    //         }
    //     }
    // }
}
