////
/// @author Joscha
/// @group MegaDropdown
////

/// Defines the Background Color of the Mega Menu Dropdown Area.
/// @type Color
$mega-menu-background-color: none !default;

/// Defines the box-shadow.
/// @type map
$mega-menu-shadow: none !default;

/// Defines the color in list li.level-2
/// @type Color
// $mega-menu-level2-color: none !default;

/// Defines the color in list li.level-3
/// @type Color
// $mega-menu-level3-color: none !default;

/// Defines the hover color in li.level-3
/// @type Color
// $mega-menu-level3-color-hover: none !default;

/// Defines the padding for <a> in li.level-3
/// See: $callout-padding
/// @type Number
// $mega-menu-level3-padding: none !default;

/// Defines the font size in li.level-3
/// See: $callout-padding
/// @type Number
// $mega-menu-level3-font-size: none !default;


// $mega-menu-font-family: none !default;
// @if $mega-menu-font-family {
//   @warn 'Variable $mega-menu-font-family is deprecated! Use nav-main instead!';
// }
// .dropdown{
//   // position: relative;
//   font-family: $mega-menu-font-family;
// }

///The mega menu style is constructed with the elements typography and background color.
///You can choose between the string "contained" and "expanded".
///The first string defines the typography and the seconds string defines the background color.
///The string "contained" contains the item to the container and the string expanded lets you expand the item over the whole page.
///@type List
$mega-menu-style: contained contained !default;

///Toggles display of an arrow if there is an nav level 2
///@type Boolean
$mega-menu-arrow: true !default;


$mega-menu-animation-speed: 0.2s;

.menu.mega-menu-dropdown {
  position: static;
}

.mega-menu {
    @if $mega-menu-arrow {
      [aria-haspopup="true"]::after{
      @include css-triangle($dropdownmenu-arrow-size, $dropdownmenu-arrow-color, down);
      margin: 8px;
      float: right;
    }
  }

  .mega-menu-pane {
    @include grid-column-gutter();
    width: 100%;
    margin: 0 auto !important;
    left: 0% !important;
    right: 0% !important;
    top: auto !important;
    position: absolute;
    z-index: 10;
    display: block;

    // {{{ Taken from sbb.ch
    pointer-events: none;
    opacity: 0;
    &.is-open {
      pointer-events: auto;
      opacity: 1;
    }
    // }}}


    .menu > li > a {
      line-height: $header-lineheight;
      padding-left: 0;
      padding-right: 0;
    }

    @if nth($mega-menu-style, 1) == contained {
      > .row {
        max-width: $global-width;
        margin-right: auto;
        margin-left: auto;
      }
    }

    @if nth($mega-menu-style, 2) == contained {
      > .row {
        background: $mega-menu-background-color;
        box-shadow: $mega-menu-shadow;
      }
    }@else {
      background: $mega-menu-background-color;
      box-shadow: $mega-menu-shadow;
    }
  }
}

@mixin mega-menu-fade-animation(){
  .mega-menu-pane {
    // transform: translateY(20px);
    // transition: transform 0.5s ease-in;
    // opacity: 0;
    transition: all $mega-menu-animation-speed ease-in;
    // &.is-open {
    //   // transform: translateY(0px);
    //   opacity: 1;
    // }
  }
}
@mixin mega-menu-flyin-animation(){
  .mega-menu-pane {
    transform: translate3d(0,20px,0);
    transition: all $mega-menu-animation-speed ease-in;
    &.is-open {
      transform: translate3d(0,0,0);
    }
  }
}

