/// Standard height for headerimage
/// @group Header
$ce-type-headerimage-styles: (
    xsmall: (
        'default': ('height': rem-calc(150)),
        'medium': ('height': rem-calc(175)),
        'large': ('height': rem-calc(200)),
    ),medium: (
        'default': ('height': rem-calc(300)),
        'medium': ('height': rem-calc(350)),
        'large': ('height': rem-calc(400)),
    )
)!default;
// $ce-type-headerimage-height-medium: rem-calc(350);
// $ce-type-headerimage-height-large: rem-calc(400);

.ce-type-headerimage {
    // height: $ce-type-headerimage-height;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    position: relative;
    overflow: hidden;

    @each $size, $frame-classes in $ce-type-headerimage-styles {
        @include breakpoint($size) {
            @each $frame-class, $frame-defs in $frame-classes {
                &.ce-frame-#{$frame-class} {
                    @each $property, $value in $frame-defs {
                        #{$property}: $value;
                    }
                }
            }
        }
    }
}