
/// Defines the border style of the newslist element.
/// See: $callout-border
/// @group Content
/// @type String
$newslist-border: $callout-border !default;

/// Defines the margin below the newslist element.
/// See: $mediaobject-margin-bottom
/// @group Content
/// @type Number
$newslist-margin-bottom: $mediaobject-margin-bottom !default;

/// Defines the padding of an item inside the newslist.
/// See: $global-padding
/// @group Content
/// @type Number
$newslist-item-padding: $global-padding 0 !default;

.ce-type-newslist {
    .ce-item {
        @include flex-grid-size($grid-column-count);
        margin-bottom: $newslist-margin-bottom !important;
        padding: $newslist-item-padding;

        &.has-image {
            @include media-object-container;
            .ce-thumbnail,
            .ce-abstract {
                @include media-object-section();
            }
            .ce-thumbnail {
                width: rem-calc(100px);
                padding-top: 0.5rem;
            }
        }
        + .ce-item {
            border-top: $newslist-border;
        }
    }
}

// .newslist-item-abstract {
//     @include flex-grid-column(12);
// }
// .newslist-item-thumbnail {
//     @include flex-grid-column(3);
//     + .newslist-item-abstract {
//         @include flex-grid-column(9);
//     }
// }