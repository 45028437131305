////
/// https://foundation.zurb.com/building-blocks/blocks/flip-card.html
/// https://codepen.io/pingmintech/pen/Kbmuk
////

$flip-card-height: rem-calc(320) !default;
$flip-card-transition-speed: 0.6s !default;

$flip-card-front-background: $primary-color !default;
$flip-card-back-background: $body-background !default;


.flip-card {
  position:relative;
  width: 100%;
  height: $flip-card-height;
  border: none;
}
.flip-card-inner-front,
.flip-card-inner-back {
    position:absolute;
    left:0;
    top:0;
    width: 100%;
    height: $flip-card-height;
    transition: $flip-card-transition-speed ease-in-out;
    backface-visibility:hidden;

  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

    h1,h2,h3,h4,h5,h6 {
      color: inherit;
    }
}
.flip-card-inner-front {
  transform:perspective(800px) rotateY(0deg);
  .flip-card.hover & {
    transform:perspective(800px) rotateY(-179.9deg);
  }
}
.flip-card-inner-back {
  transform:perspective(800px) rotateY(180deg);
  .flip-card.hover & {
    transform:perspective(800px) rotateY(0);
  }
}


// .flip-card {
//   perspective: 1000;
//   border: 0;
//   background: transparent;

//   // &:hover .flip-card-inner,
//   &.hover .flip-card-inner {
//     transform: rotateY(180deg);
//   }
// }

// .flip-card,
// .flip-card-inner-front,
// .flip-card-inner-back {
//   width: 100%;
//   height: $flip-card-height;
// }

// .flip-card-inner {
//   transition: $flip-card-transition-speed;
//   transform-style: preserve-3d;
//   position: relative;
//   backface-visibility: hidden;
// }

// .flip-card-inner-front,
// .flip-card-inner-back {
//   backface-visibility: hidden;
//   position: absolute;
//   top: 0;
//   left: 0;
//   h1,h2,h3,h4,h5,h6 {
//     color: inherit;
//   }
// }

// // non-essential front and rear styles
// .flip-card-inner-front {
//   background: $flip-card-front-background;
//   z-index: 2;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   text-align: center;

//   // span {
//   //   width: 100%;
//   //   text-align: center;
//   //   background: rgba($white, 0.8);
//   //   padding: 0.25rem 0;
//   //   font-size: 1.25rem;
//   //   font-weight: bold;
//   // }
// }

// .flip-card-inner-back {
//   transform: rotateY(180deg);
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   flex-direction: column;
//   text-align: center;
//   background: $flip-card-back-background;
//   padding: 1rem;
// }

// .flip-card-inner-back-title {
//   font-weight: bold;
// }

// .flip-card-inner-back-text {
//   line-height: 1.3;
// }

