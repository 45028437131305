/// Defines the font-size of the menu subpages if not default.
/// @group Header
/// @type String
$menusubpages-font-size: $global-font-size !default;

/// Defines the font-weight of the menu subpages if not default.
/// @group Header
/// @type String
$menusubpages-font-weight: $global-weight-normal !default;


/// Defines the space between the different menu utems in menu.
/// @group Header
/// @type String
$menusubpages-margin: $global-margin !default;


.ce-type-menusubpages {
  .menu {
    li {
      a {
        font-size: $menusubpages-font-size;
        font-weight: $menusubpages-font-weight;
        margin: $menusubpages-margin;
      }
    }
  }
}

@mixin menu-color($default, $hover: null, $active: null, $current: null)
{
  @if $hover == null {
    $hover: scale-color($default, $lightness: -14%);
  }
  @if $active == null {
    $active: scale-color($default, $lightness: -14%);
  }
  @if $current == null {
    $current: scale-color($default, $lightness: -14%);
  }
  .menu {
    a {
      color: $default;
      &:hover {
        color: $hover;
      }
    }
    .is-menuitem-active {
      a {
        color: $active;
      }
    }
    .is-menuitem-current {
      a {
        color: $current;
      }
    }
  }
}

@mixin menu-underline ($border-style, $expand-to-text: true)
{
  .menu {
    a {
        position: relative;

      .menu-item-inner {

        &::after {
          content: " ";
          position: absolute;
          bottom: 0;

          @if $expand-to-text == true {
            left: get-side($menu-items-padding, left);
            right: get-side($menu-items-padding, right);

          }@else {
            left: 0;
            right: 0;
          }
          border: $border-style;
        }
      }
    }
  }
}

$menu-underline-animation-speed: 0.2s !default;

//
@mixin menu-underline-animation-expand($speed: $menu-underline-animation-speed) {
  .menu {
    a {
      .menu-item-inner {
        &::after {
          transition: transform ease $speed;
          transform: scaleX(0);
        }
      }
      &:hover {
        .menu-item-inner {
          &::after {
            transform: scaleX(1);
          }
        }
      }
    }
  }
}
@mixin menu-underline-animation-fade($speed: $menu-underline-animation-speed) {
  .menu {
    a {
      .menu-item-inner {
        &::after {
          transition: opacity ease $speed;
          opacity: 0;
        }
      }
      &:hover {
        .menu-item-inner {
          &::after {
            opacity: 1;
          }
        }
      }
    }
  }
}

// @mixin animation-transition ($animation-transition-transform, $animation-transition-duration, $animation-transition-curve){
//     a{
//     .menu-item-inner {
//       &::after {
//         transition: $animation-transition-transform $animation-transition-duration $animation-transition-curve;
//       }
//     }
//   }
// }

// @mixin animation-style ($animation-start-from-center, $animation-fade-in){
//   a{
//     @if $animation-start-from-center == true {
//       .menu-item-inner {
//         &::after {
//           transform: scaleX(0);
//         }
//       }
//       &:hover {
//         .menu-item-inner {
//           &::after {
//             transform: scaleX(1);
//           }
//         }
//       }
//     }
//     @if $animation-fade-in == true {
//       .menu-item-inner {
//         &::after {
//           opacity: 0;
//         }
//       }
//       &:hover {
//         .menu-item-inner {
//           &::after {
//             opacity: 1;
//           }
//         }
//       }
//     }
//   }
// }

// @mixin font-weight-control ($default, $bold){
//   @if $default == true{
//       font-weight: regular;
//   }
//   @if $bold == true {
//       font-weight: bold;
//   }
// }







@mixin menu-link-shrink()
{
  a {
    display: inline-block;
  }
}

