/// Puts the subheader above the header so there is a "spitzmarke" above the main header.
/// @group Content
/// @type Bool
$ce-header-subheader-above: true !default;

/// Defines the border style of the frame box.
/// @group Content
/// @type String
$ce-frame-box-border: 1px solid $medium-gray !default;

/// Defines the background style of the frame box.
/// See: $light-gray
/// @group Content
/// @type Color
$ce-frame-box-background-color: $light-gray !default;

/// Defines the padding inside the frame box.
/// See: $callout-padding
/// @group Content
/// @type Number
$ce-frame-box-padding: $callout-padding !default;

/// Defines the scaling of the header text size inside the frame box.
/// @group Content
/// @type Number
$ce-frame-box-scale-header-font-size: 1 !default;

/// Sets the necessary space, so a download text doesn't wrap around the accompanying icon.
/// The space is dependent of the width of the used icon but is optimized for all fa-file-... icons font awesome has to offer.
/// @group Content
/// @type Number
$ce-frame-box-downloadlist-icon-space: 20px;

/// Margin between all content elements.
/// @group Content
/// @type Number
$ce-base-space: $global-margin !default;

/// Change link color for headlines
/// Use inherit if you like to use the normal headline color as link color.
/// @group Header
/// @type Color
$ce-header-anchor-color: default !default;

$ce-header-subheader-above-color: $black !default;



// $default-space: $global-margin !default;


// $ce-space-extra-large: $default-space * 5;
// $ce-space-large: $default-space * 4;
// $ce-space-medium: $default-space * 3;
// $ce-space-small: $default-space * 2;
// $ce-space-extra-small: $default-space;


$ce-spaces: (
  xsmall: (
    'default': (content: $ce-base-space * 1, aside: $ce-base-space * 1),
    'small': (content: $ce-base-space * 0.15, aside: $ce-base-space * 0.15),
    'large': (content: $ce-base-space * 1.5, aside: $ce-base-space * 0.75),
  ),
  medium: (
    'default': (content: $ce-base-space * 1, aside: $ce-base-space * 0.5),
    'small': (content: $ce-base-space * 0.25, aside: $ce-base-space * 0.25),
    'large': (content: $ce-base-space * 1.5, aside: $ce-base-space * 0.75),
  ),
) !default;



.ce {

    // Because we define the space between ce in the element itself so we dont need a second source of space
    // @each $size, $margin in $ce-margin {
    //     @if $margin {
    //         @include breakpoint($size) {
    //             + .ce {
    //                 margin-top: $ce-margin;
    //             }
    //         }
    //     }
    // }

    @each $size, $spaces in $ce-spaces {
        @include breakpoint($size) {
            @each $space, $space-defs in $spaces {

                    @if $space == 'default' {
                        + .ce {
                            margin-top: map-get($space-defs, content);
                            .content-section-aside & {
                                margin-top: map-get($space-defs, aside);
                            }
                        }
                    }@else {
                        &.row.ce-space-before-#{$space} {
                            margin-top: map-get($space-defs, content);
                            .content-section-aside & {
                                margin-top: map-get($space-defs, aside);
                            }
                        }
                        &.row.ce-space-after-#{$space} {
                            margin-bottom: map-get($space-defs, content);
                            .content-section-aside & {
                                margin-bottom: map-get($space-defs, aside);
                            }
                        }
                    }

            }
        }
    }
    // &-space-before {

    //     &-extra-large {
    //         margin-top: $ce-space-extra-large;
    //     }

    //     &-large {
    //         margin-top: $ce-space-large;
    //     }

    //     &-medium {
    //         margin-top: $ce-space-medium;
    //     }

    //     &-small {
    //         margin-top: $ce-space-small;
    //     }

    //     &-extra-small {
    //         margin-top: $ce-space-extra-small;
    //     }
    // }

    // &-space-after {
    //     &-extra-large {
    //         margin-bottom: $ce-space-extra-large;
    //     }
    //     &-large {
    //         margin-bottom: $ce-space-large;
    //     }

    //     &-medium {
    //         margin-bottom: $ce-space-medium;
    //     }

    //     &-small {
    //         margin-bottom: $ce-space-small;
    //     }

    //     &-extra-small {
    //         margin-bottom: $ce-space-extra-small;
    //     }
    // }


    @if $ce-header-anchor-color != default {
        h1, h2, h3, h4, h5, h6 {
            > a {
                color: $ce-header-anchor-color;
                &:hover {
                    color: $anchor-color-hover;
                }
            }
        }
    }
}




.ce-headline-left {
    text-align: left;
}
.ce-headline-right {
    text-align: right;
}
.ce-headline-center {
    text-align: center;
}


// The following rule is temporarily commentet out to test the behavior of the space definded in the conent elements
//
//  Remove header margin for first content element.
// .content-section {
//     .ce {
//         &:first-child {
//             header{
//                 @if $ce-header-subheader-above {
//                     margin-top: 0;
//                 }@else {
//                     > *:first-child {
//                         margin-top: 0;
//                     }
//                 }

//             }
//         }
//     }
// }

/// Standard frame box
.ce-frame-box {
    .ce-inner {
        border: $ce-frame-box-border;
        background-color: $ce-frame-box-background-color;
        padding: $ce-frame-box-padding;
        // margin-bottom: $global-margin;
        h1, h2, h3, h4, h5, h6 {
            margin-top: $global-margin;
        }
        p, a {
            margin-top: $global-margin;
        }
        //If there is a list with a file icon, this is the definition so the text doesn't wrap around the icon.
        .no-bullet {
            margin-left: $ce-frame-box-downloadlist-icon-space;
            i {
                margin-left: -$ce-frame-box-downloadlist-icon-space;
            }
        }
    }

      // If header is in a box, header doesnt have space on top
    @if $ce-header-subheader-above {
        .ce-header-layout-h1,
        .ce-header-layout-h2,
        .ce-header-layout-h3,
        .ce-header-layout-h4,
        .ce-header-layout-h5,
        .ce-header-layout-h6{
            margin-top: 0 !important;
        }
    }@else{
        header{
            h1, h2, h3, h4, h5, h6 {
                margin-top: 0 !important;
            }
        }
    }

    /// Adjust size of headline in box
    @each $size, $headers in $header-styles {
        @include breakpoint($size) {
            @each $header, $header-defs in $headers {
                #{$header} {

                    @if map-has-key($header-defs, font-size) {
                        font-size: $ce-frame-box-scale-header-font-size * rem-calc(map-get($header-defs, font-size));
                    }
                }
            }
        }
    }
}
/// Spitzmarke Definition
.ce header {

    @if $ce-header-subheader-above {
        display: flex;
        flex-direction: column-reverse;

        @if $ce-header-subheader-above-color != inherit {
            > *:nth-child(2) {
                color: $ce-header-subheader-above-color;

            }
        }
        h1, h2, h3, h4, h5, h6 {
            margin-top: 0;
        }

        @each $size, $headers in $header-styles {
            @include breakpoint($size) {
                @each $header, $header-defs in $headers {
                    &.ce-header-layout-#{$header} {

                        @if map-has-key($header-defs, 'margin-top') {
                            margin-top: (map-get($header-defs, 'margin-top'));
                        }
                    }
                }
            }
        }
    }
}
