// $offcanvas-menu-padding: 0 !default;
// $offcanvas-menu-margin: 0rem !default;

// $offcanvas-menu-item-padding: 0.5rem !default;
// $offcanvas-menu-item-margin: 0rem !default;

/// Offcanvas anchor color
/// @Type Color
$offcanvas-anchor-color: $anchor-color !default;

/// Offcanvas anchor color hover state
/// @Type Color
$offcanvas-anchor-color-hover: scale-color($offcanvas-anchor-color, $lightness: -14%) !default;

.off-canvas {
    nav {
        margin-top: 2.5rem;
    }


    @if $offcanvas-anchor-color != $anchor-color {
        a {
            color: $offcanvas-anchor-color;
            &:hover {
                color: $offcanvas-anchor-color-hover;
            }
        }
    }

    // .menu {
    //     margin: $offcanvas-menu-margin;
    //     margin-top: 2.5rem;
    //     li {
    //         padding: $offcanvas-menu-item-padding;
    //         margin: $offcanvas-menu-item-margin;
    //     }
    // }
}