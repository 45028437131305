@mixin frp-grid-property(
    $property,
    $gutter: auto,
    $gutters: $grid-column-gutter
) {
    @include -zf-breakpoint-value($gutter, $gutters) {
        $padding: rem-calc($-zf-bp-value) / 2;

        #{$property}: $padding;
    }
}

.ce-type-textmedia {
    // &.layout-11 {
    //     .ce-inner {
    //         @include callout();
    //     }
    // }
    img[data-original-src] {
        cursor: pointer;
    }

    figure {
        @include grid-column-margin();
    }

    .align-center {
        .column {
            text-align: center; // Bugfix 1 column top
        }
    }

    .in-text {
        @include grid-column(round($grid-column-count / 2));
        &.left {
            @include frp-grid-property('margin-right');
            padding-left: 0;
            float: left;
            &.layout-12 {
                width: 33.333%;
            }
            &.layout-21 {
                width: 66.666%;
            }
        }
        &.right {
            @include frp-grid-property('margin-left');
            padding-right: 0;
            float: right;
            &.layout-12 {
                width: 66.666%;
            }
            &.layout-21 {
                width: 33.333%;
            }
        }

        &.layout-11 {
            width: 50%;
        }
    }
}