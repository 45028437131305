/// Defines the color of the adress if not default.
/// @group Address
/// @type Color
$address-color: none !default;


.ce-type-address {

    .address {
        font-style: normal;
        @if $address-color != none {
        color: $address-color;
        }
    }

    &.ce-layout-0 {
        // Block format
        .address-name,
        .address-streetaddress,
        .address-addresslocality,
        .address-telephone-wrapper,
        .address-email,
        .address-openinghours,
        .address-locality + .address-country {
            display: block;
        }
        .address-openinghours {
            // display: table;
            > .address-openinghours-group {
                display: table-row;
                > * {
                    display: table-cell;
                    padding-right: 5px;
                }
            }
        }
    }

    &.ce-layout-11 {
        // Inline format
        .address-address,
        .address-name,
        .address-telephone-wrapper {
            &:after {
                content: '|';
                display: inline-block;
                vertical-align: baseline;
                margin-left: .25em;
            }
        }
        .ce-inner div,
        .ce-inner span {
            display: inline-block;
            vertical-align: baseline;
            margin: 0;
            padding: 0;
        }
        .address-openinghours-title {
            font-size: inherit;
            font-weight: inherit;
            color: inherit;
            margin: inherit;
            display: inline-block;
            &:after {
                content: ':';
                display: inline-block;
            }
        }
        .address-openinghours-group {
            //  Comma is hardcoded in template!
            // .openinghour {
            //     &:after {
            //         content: ',';
            //         display: inline-block;
            //     }
            //     &:last-child {
            //         &:after {
            //             content: '';
            //         }
            //     }
            // }
            color: inherit; // Why? Otherwise after is using a comma?!?
            &:after {
                content: '|';
                display: inline-block;
            }
            &:last-child {
                &:after {
                    content: '';
                }
            }
        }
    }
}
