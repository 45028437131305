.ce-type-teaserlatestsubpages {
	.card {
		box-sizing: border-box;
		display: flex;
		flex-direction: column;
		height: 100%;
		.card-section {
			margin-top: auto;
		}
	}
}