.ce-type-thumnailbslider{
    button.slick-arrow{
        &:before{
            color: black;
        }
    }

    .column{
        max-width: 100%;
    }

    .slick-next-custom, .slick-prev-custom{
        position: absolute;
        z-index: 100;
        top: 50%;
        width: 1.6458333333rem !important;
        height: 1.6458333333rem !important;
        padding: 0;
        background: transparent url("/typo3conf/ext/frp_template/Resources/Public/Icons/Content/arrowLeft.svg") center no-repeat;
        background-size: 1.6458333333rem 1.6458333333rem;
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
        &:hover{
            cursor: pointer;
        }
    }

    .slick-prev-custom{
        left: -25px;
    }

    .slick-next-custom{
        right: -25px;
        transform: rotate(180deg) translateY(50%);
    }

    .slider-nav{
        .slick-slide{
            padding: 25px;
            &:focus{
                outline: none;
            }
        }
    }

    .slider-pane{
        figure.slick-slide{
            img{
                margin: auto;
            }
        }
    }
}