.ce-type-product,
.ce-type-productlist {

	.slider-nav {
		margin-top: $global-margin;
		.slick-slide {
			cursor: pointer;
		}
	}
    .slick-slide {
        img {
            margin-left: auto;
            margin-right: auto;
        }
        &:focus {
        	outline: none;
        }
    }
    .slick-dots {
    	font-size: 2rem;
    }
    .slick-track {
    	.slick-slide:first-child img {
    		margin-left: 0;
    	}
    }
    .specialOfferPrice {
    	font-weight: $global-weight-bold;
    	color: $primary-color;
    }
}