/// Defines default gutter space in the grid
/// @group Content
/// @todo Check description and naming with r3h6.
/// @type Number
$ce-grid-gutter: rem-calc(30px) !default; // Same as the grid

@function frp-calc-row-margin ($context: $grid-column-count) {
    $context-width: $global-width / $grid-column-count * $context;
    @return percentage(($ce-grid-gutter / 2) / $context-width);
}

@function frp-calc-column-gutter ($context: $grid-column-count) {
    $context-width: $global-width / $grid-column-count * $context;
    @return percentage(($ce-grid-gutter / 2) / ($context-width + $ce-grid-gutter));
}

.ce-type-masonrygallery {
    // Make the gutters fluid...
    .ce-inner {
        .column {
            @include grid-column-margin();
        }
        @include breakpoint(small) {
            .column {
                margin-bottom: 0;
            }
            .row {
                margin-left: -1 * frp-calc-column-gutter();
                margin-right: -1 * frp-calc-column-gutter();
                + .row {
                    margin-top: 2 * frp-calc-row-margin();
                }
                .column {
                    padding-left: frp-calc-column-gutter();
                    padding-right: frp-calc-column-gutter();
                }
            }

            .small-6 {
                .row {
                    margin-left: -1 * frp-calc-column-gutter(6);
                    margin-right: -1 * frp-calc-column-gutter(6);
                    + .row {
                        margin-top: 2 * frp-calc-row-margin(6);
                    }
                    .column {
                        padding-left: frp-calc-column-gutter(6);
                        padding-right: frp-calc-column-gutter(6);
                    }
                }
            }

            .small-9 {
                .row {
                    margin-left: -1 * frp-calc-column-gutter(9);
                    margin-right: -1 * frp-calc-column-gutter(9);
                    + .row {
                        margin-top: 2 * frp-calc-row-margin(9);
                    }
                    .column {
                        padding-left: frp-calc-column-gutter(9);
                        padding-right: frp-calc-column-gutter(9);
                    }
                }
            }
        }
    }

    // Image caption overlay
    .image {
        overflow: hidden;
        position: relative;
        .caption {
            > .overlay {
                position: absolute;
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                justify-content: center;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                background-color: rgba(0,0,0,.75);
                opacity: 0;
                transition: opacity 0.3s ease-in;
                &:hover {
                    opacity: 1;
                }
                // &.is-clickthrough {
                //     pointer-events: none;
                // }
            }
            .title {
                text-align: center;
                display: block;
                font-weight: bold;
            }
            .description {
                text-align: center;
                display: block;
            }
        }
    }
}