// .ce-type-heroslider {

.ce-type-heroslider {
    .orbit-container {

    }
    .orbit-slide {
        height: 300px;
        background-color: $secondary-color;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: bottom center;
        border-top: 0.5rem solid $secondary-color;
        @include breakpoint (medium){
            height: 400px;
            border-top: none;
        };
    }

    li.orbit-slide .orbit-slide-inner{
        display: table;
        width: 100%;
        height: 100%;
    }

    .orbit-text {
        // text-align: left;
        // margin-left: 1rem;
        h1 {
            color: $white;
            margin-bottom: 0;
        }
        h3 {
            color: $white;
        }

        display: table-cell;
        &.left{
            text-align: left;
        }
        &.center{
            text-align: center;
        }
        &.right{
            text-align: right;
        }
        &.top{
            vertical-align: super;
        }
        &.middle{
            vertical-align: middle;
        }
        &.bottom{
            vertical-align: bottom;
        }

        @include breakpoint (medium){
            // text-align: right;
            // margin-right: 1rem;
            // padding-top: 2rem;
            h1 {
                font-size: rem-calc(34);
            }
            h3 {
                font-size: rem-calc(18);
            }
        }

        @include breakpoint (xlarge){
            margin-right: 5%;
            h1 {
                font-size: rem-calc(48);
            }
            h3 {
                font-size: rem-calc(24);
            }
        }
    }
}






//     .orbit-text{
//         display: table-cell;
//         &.left{
//             text-align: left;
//         }
//         &.center{
//             text-align: center;
//         }
//         &.right{
//             text-align: right;
//         }
//         &.top{
//             vertical-align: super;
//         }
//         &.middle{
//             vertical-align: middle;
//         }
//         &.bottom{
//             vertical-align: bottom;
//         }
//     }
// }

