@charset 'utf-8';
@import 'settings/settings';
@import 'partials/foundation';
@import 'partials/modular';

footer.page-footer{
    color: #25C421;
    margin-top: 3rem;
    a{
        color: #25C421;
    }
}

@media print, screen and(min-width: 48em){
    .ce-type-headerimage.ce-frame-default{
        height: rem-calc(400px);
    }
}

@media print, screen and(min-width: 36em) and (max-width: 48em){
    .ce-type-headerimage.ce-frame-default{
        height: rem-calc(250px);
    }
}

$page-header-brakpoint: large;
.nav-main .menu{
    float: right;
}

.ce-type-filelist ul{
    list-style-type: none;
    margin-left: 0;
    a{
        text-decoration: underline;
    }
}

ul.vertical.accordion-menu{
    margin-top: 36px;
}

li.menu-item a{
    color: #4A4A4A;
}

li.menu-item.is-menuitem-current a{
    position: relative;
    &:before{
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        border-bottom: 2px solid #25C421;
        margin: 0.7rem 1rem;
        margin-top: 0.7rem;
        margin-bottom: 0.7rem;
        margin-top: 0;
        margin-bottom: 0;
    }
}