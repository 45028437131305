@mixin grid-column-gap(
    $gutter: auto,
    $gutters: $grid-column-gutter
) {
    @include -zf-breakpoint-value($gutter, $gutters) {
        $padding: rem-calc($-zf-bp-value);

        column-gap: $padding;
    }
}

.ce-type-text {
    // &.layout-11 {
    //     .ce-inner {
    //         @include callout();
    //     }
    // }
    header {
        p {
            margin: 0;
        }
    }
    @include breakpoint(medium){
        @for $i from 1 through 8 {
            .ce-columns-#{$i} {
                @include grid-column-gap();
                columns: $i;
            }
        }
    }
}