.ce-type-googlemap {
    .map-marker {
        padding: $global-padding;
        position: relative;
        background-color: $primary-color;
        box-shadow: none;
        color: $white;
        font-size: .9rem;
        &:after {
            border: .25rem solid $primary-color;
            content: '';
            height: 0;
            width: 0;
            position: absolute;
            pointer-events:none;
            top: calc(100% - .25rem); left: 50%;
            transform: translateX(-50%) rotate(-45deg);
        }
        p {
            margin: 0;
            & + p {
                margin-top: $paragraph-margin-bottom;
            }
        }
        a {
            color: $secondary-color;
        }
    }

    .map-holder {
        position: relative;
        width: 100%;
        height: 0;
        padding-bottom: percentage(1/3);
        background-color: #f0f0f0;
        .map-placeholder {
            position: absolute;
            width: 100%;
            height: 100%;
        }
    }
}