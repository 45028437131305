/// Defines the color of the name in the vcard if not default.
/// @group Vcard
/// @type Color
$vcard-n-color: none !default;


/// Defines the font-weight of the name in the vcard if not default.
/// @group Vcard
/// @type String
$vcard-n-font-weight: none !default;



/// Defines the font-weight of the name in the vcard if not default.
/// @group Vcard
/// @type String
$vcard-label-color: $medium-gray !default;

$vcard-label-display: true !default;


.ce-type-vcard {
    margin-bottom: $global-margin;
    label {
        display: inline-block;
        color: $vcard-label-color;
        font-size: $global-font-size;

        @if $vcard-label-display != true {
        display: none;
        }
    }
    .vcard-n {
        @if $vcard-n-font-weight != none {
        font-weight: $vcard-n-font-weight;
        }
        @if $vcard-n-color != none {
        color: $vcard-n-color;
        }
    }
}