
/// hide the first mediafile when true (first file used in newslist thumbnail)
/// @group Content
/// @type boolean
$newsarticle-first-media-disable: false !default;


.ce-type-newsarticle {
    .ce-inner {
        figure {
            video, img, audio {
                max-width: 100%;
            }
            @if $newsarticle-first-media-disable == true {
                &:first-of-type {
                    display: none;
                    visibility: 0;
                }
            }
        }
    }
}