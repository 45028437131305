.teaserimg {
padding-top: 55%;
background-size: contain;
background-repeat: no-repeat;

    @include breakpoint(medium) {
    padding-top: 0rem;
    background-size: auto;
    }
}
.teasertext {
    background-color: $light-gray;
    padding: $global-padding;
}

