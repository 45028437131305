/// Defines the font color of the page footer.
/// @group Footer
/// @type Color
$page-footer-color: inherit !default;

/// Defines the header color of the page footer.
/// @group Footer
/// @type Color
$page-footer-header-color: inherit !default;

/// Defines the background of the page footer.
/// @group Footer
/// @todo Please clarify what this variable does. If this variable changes the background color, name the variable $page-footer-background-color and create a new variable if there is the possibility to set a background image $page-footer-background-image.
$page-footer-background: inherit !default;

/// Defines the padding space inside the page footer.
/// @group Footer
/// @type Number
$page-footer-padding: none !default;

/// Defines the padding space between the footer and the corresponding break line above.
/// @group Footer
/// @type
$page-footer-inline-padding-top: none !default;

/// Defines the margin space outside the page footer.
/// @group Footer
/// @type Number
$page-footer-margin: none !default;

/// Defines the font size inside the page footer.
/// @group Footer
/// @type Number
$page-footer-font-size: inherit !default;

/// Defines the border style inside the page footer.
/// @group Footer
/// @type String
$page-footer-inner-border-top-style: none !default;

/// Defines the text alignment of footer text.
/// @group Footer
/// @type String
$page-footer-text-align: left !default;

/// Footer link color
/// @group Footer
/// @type Color
$page-footer-anchor-color: inherit !default;

/// Footer link color hover state
/// @group Footer
/// @type Color
$page-footer-anchor-color-hover: inherit !default;

.page-footer {
    text-align: $page-footer-text-align;

    @if $page-footer-font-size != inherit {
        font-size: $page-footer-font-size;
    }
    @if $page-footer-color != inherit {
        color: $page-footer-color;
    }
    @if $page-footer-background != inherit {
        background: $page-footer-background;
    }
    @if $page-footer-padding != none {
        padding: $page-footer-padding;
    }
    @if $page-footer-margin != none {
        margin-top: $page-footer-margin;
    }
    @if $page-footer-anchor-color != inherit {
        a {
            color: $page-footer-anchor-color;
        }
    }
    @if $page-footer-anchor-color-hover != inherit {
        a:hover {
            color: $page-footer-anchor-color-hover;
        }
    }
    @if $page-footer-header-color  != inherit {
        h1, h2, h3, h4, h5, h6 {
            color: $page-footer-header-color;
        }
    }

}
.page-footer-inline {
    @if $page-footer-inner-border-top-style != none {
        border-top: $page-footer-inner-border-top-style;
    }

    @if $page-footer-inline-padding-top != none {
        padding: $page-footer-inline-padding-top;
    }
}