/// Defines the padding top of the content section on a onepage.
/// @group Content
/// @type String
$content-padding-top: $page-topbar-height !default;

/// Defines the offset space of an anchor jump considering the header height on a onepage.
/// @group Content
/// @type String
$anchor-offset: $page-topbar-height !default;

/// Defines the offset space of an anchor jump on mobile devices considering the header height on a onepage.
/// @group Content
/// @type String
$anchor-offset-mobile: $page-topbar-height * 0.7 !default;

.onepage-section {

    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    padding-top: 1rem;
    padding-bottom: 1rem;


    @include breakpoint($page-header-breakpoint) {
    padding-top: $content-padding-top;
    padding-bottom: 5rem;
}




    &.layout-11 {
        background-color: $primary-color;
    }

    &.layout-12 {
        background-color: $secondary-color;
    }

    &.layout-21 {
        background-attachment: fixed;
    }
}

.anchor {
    margin-top: -$anchor-offset-mobile;
    display: block;
    height: $anchor-offset-mobile;


    @include breakpoint(medium) {
    margin-top: -$anchor-offset;
    position: relative;
    display: block;
    height: $anchor-offset;
    width: 0px;
    }
}



