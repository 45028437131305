/// Defines border bottom styles of the top bar.
/// @group Header
/// @type String
$page-topbar-border-bottom: none !default;

/// Defines border bottom styles of the title bar.
/// @group Header
/// @type String
$page-titlebar-border-bottom: none !default;

// $page-header-bar-padding: $global-padding !default;
// $page-header-bar-background-color: $titlebar-background !default;
.page-header {
    // padding: $page-header-bar-padding;
    // background-color: $page-header-bar-background-color;
    &.sticky-container {
        z-index: 1; // Fix submenu clipping for droppdowns
    }
}

.page-topbar {
    @if $page-topbar-border-bottom != none {
        border-bottom: $page-topbar-border-bottom;
    }
}

.page-titlebar {
    @if $page-titlebar-border-bottom != none {
        border-bottom: $page-titlebar-border-bottom;
    }
}


