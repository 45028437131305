/// Standard height for titlebar
/// @group Header
/// @type Number
$titlebar-height: 0 !default; // over-ride this variable in your own extension

.ce-type-herofullscreen {
    margin-top: 0;
    @if $titlebar-height != 0 {
        height: calc(100vh - #{$titlebar-height});
    }@else {
        height: 100vh;
    }

    // If the content element is inside a cell (column), adjust height to cell (column).
    .cell > &,
    .column > &,
    .columns > & {
        height: 100%;
    }
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    align-items: center;
    position: relative;

    .ce-inner {
        position: absolute;
        top: 50%; left: 50%;
        transform: translate(-50%,-50%);
    }

    :not(:first-child){
        .goto-content {
            display: none;
        }
    }
}