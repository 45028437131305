///Defines space below image description and next element.
///See: $paragraph-margin-bottom
/// @group Content
/// @type Number
$ce-images-figure-margin-bottom: $paragraph-margin-bottom !default;

.ce-type-images {

    img[data-original-src] {
        cursor: pointer;
    }

    figure {
        margin-bottom: $ce-images-figure-margin-bottom;
    }
}