.ce-type-productlist {
    .product {
        @include card-container();
        // > .productlist-product-media {
        //     @include card-section;
        // }
        .product-header {
            @include card-section;
        }
        .product-body {
            @include card-section;
            padding-top: 0;
        }
        .product-footer {
            @include card-section;
            padding-top: 0;
        }
    }
}