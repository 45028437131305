$ce-type-herosection-text-position: center center !default;

$ce-type-herosection-padding: null !default;

$ce-type-herosection-color: $white !default;

$ce-type-herosection-background: transparent !default;

@mixin flexPosition($position){
    $horizontal: nth($ce-type-herosection-text-position, 1);
    $vertical: nth($ce-type-herosection-text-position, 2);

    @if $horizontal == left {
        justify-content: flex-start;
    }@elseif $horizontal == right {
        justify-content: flex-end;
    }@else {
        align-items: $vertical;
    }

    @if $vertical == top {
        align-items: flex-start;
    }@elseif $vertical == bottom {
        align-items: flex-end;
    }@else {
        align-items: $vertical;
    }
}

.ce-type-herosection {
    height: 50vh;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    text-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
    .herosection-wrapper {
        @include flexPosition($ce-type-herosection-text-position);
        width: 100%;
        height: 100%;
        display: flex;
        background: $ce-type-herosection-background;

        color: $ce-type-herosection-color;

        @if $ce-type-herosection-padding != null {
            padding: $ce-type-herosection-padding;
        }
    }
    h1,h2,h3,h4,h5,h6 {
        color: inherit;
    }
}