/// Local variable, use $form-label-font-weight.
/// See: $form-label-font-weight
/// @access private
$ce-form-label-weight: $form-label-font-weight !default;

/// Number of columns for a group of checkboxes.
/// @group Content
/// @type Number
$ce-form-checkbox-columns: 3 !default;

/// Number of columns for a group of radio buttons.
/// @group Content
/// @type Number
$ce-form-radio-columns: 3 !default;

/// Fieldset top and bottom margin.
/// @group Content
/// @type Number
$ce-form-fieldset-spaceing: 2.5rem;


.control-label,
label[for*="advancedpassword"] {
    // font-weight: $ce-form-label-weight;
    .has-error & {
        color: $alert-color;
    }
}

.form-control {
    .has-error & {
        @include form-input-error;
    }
}

.ce-form {
    @for $i from 1 to $grid-column-count {
        .col-xs-#{$i} {
            @include flex-grid-column($i);
        }
    }

    @include breakpoint(small) {
        @for $i from 1 to $grid-column-count {
            .col-sm-#{$i} {
                @include flex-grid-column($i);
            }
        }
    }

    @include breakpoint(medium) {
        @for $i from 1 to $grid-column-count {
            .col-md-#{$i} {
                @include flex-grid-column($i);
            }
        }
    }

    @include breakpoint(large) {
        @for $i from 1 to $grid-column-count {
            .col-lg-#{$i} {
                @include flex-grid-column($i);
            }
        }
    }



     fieldset.form-group {
         margin-top: $ce-form-fieldset-spaceing;
         margin-bottom: $ce-form-fieldset-spaceing;
         > legend {
            font-size: $lead-font-size;
         }
     }

    // Here we display the checkboxes in specialy defined columns
    .checkbox {
        .inputs-list {
            @include flex-grid-row(nest);
            @include flex-grid-layout($ce-form-checkbox-columns,".form-check");
            .form-check {
                //  @include flex-grid-column;
                @include grid-column-gutter();
                margin-bottom: 8px;
            }
        }
    }

    .form-check {
        margin-bottom: 1rem;
    }

    // Because there is no special identificator between radio buttons and checkboxes we have to select the checkbox like we did below
    .inputs-list {
        .form-group {
            @include flex-grid-row(nest);
            @include flex-grid-layout($ce-form-radio-columns,".form-check");
            .form-check {
                //  @include flex-grid-column;
                @include grid-column-gutter();
                margin-bottom: 8px;
            }
        }
    }

    .form-check-label {
        font-weight: $global-weight-normal;
        padding-left: 20px;
        position: relative;
        line-height: 1.25;

        input{
            position: absolute;
            margin-left: -20px;
            margin-top: 3px;
        }

     }
     .actions {
         margin-top: 1.5rem;
     }

     .error{
         @include form-error;
         display: block;
     }

    .checkbox {
        .error {
            margin-top: 0;
        }
    }
}
